
//Tablet styles
@media (min-width: 768px) {
  #navbar {
    min-height: 49px;
    overflow: visible;
  }
  #user-widget {
    height: 49px;
  }
  .region-navigation {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  #block-universal-navbar-universal-navbar-account .contextual-links-wrapper {
    right: -27px;
  }
  #global-links,
  #account-links {
    margin: 0;
    padding: 0;
  }
  #global-links > li,
  #account-links > li {
    display: inline-block;
    padding: 0 5px;
    height: 49px;
    > a {
      display: inline-block;
    }
    ul.dropdown {
      display: none;
      position: absolute;
      top: 48px;
      min-width: 150px;
    }
    &:hover ul.dropdown {
      display: block;
    }
  }
  #block-blimp-platform-unified-cg-nav {
    float: left;
  }
  #block-blimp-platform-unified-cg-account {
    float: right;
  }
  ul.user-profile-category {
    &.user-information {
      li {
        float: left;
        height: 150px;
        margin-right: 2%;
        width: 30%;
      }
    }
    &.user-recent-orders {
      table {
        th {
          font-size: 100%;
        }
      }
    }
  }
  .service-info {
    .service-item {
      float: left;
    }
    .service-support .service-header {
      background: #00adef url("@{image-path}/bg-services-icon.png") no-repeat 90% center;
    }
    .service-platform .service-header {
      background: #000 url("@{image-path}/bg-services-platform.png") no-repeat 95% bottom;
    }
  }
  .view-platforms-user-view .views-row {
    float: left;
  }
  #user-profile-form,
  .user-billing-address,
  .user-ssh-keys {
    > div > fieldset,
    > div > .form-wrapper {
      margin-right: 230px;
    }
    > div > .field-type-cl-billing-pricing {
      margin-right: 0;
    }
  }
  #edit-field-profile-picture,
  #edit-picture {
    position: absolute;
    top: 40px;
    right: 40px;
    margin-right: 0 !important;
    width: 200px;
  }
  .account-settings-tab {
    .tabs--secondary {
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 0;
      width: 160px;
      padding-top: 0;
      border-top: 1px solid @body-bg;
      li {
        display: block;
        width: 100%;
        float: left;
        clear: left;
        a {
          color: #6c6c6c;
          display: block;
          width: 100%;
          border: none;
          border-radius: 0;
          padding: 20px;
          background: transparent;
          font-size: 12px;
          text-transform: uppercase;
          &:hover {
            color: @brand-primary;
          }
        }
        &.active a {
          background: #fff;
          color: #6c6c6c;
          font-weight: bold;
        }
      }
    }
    #block-system-main {
      margin-left: 159px;
      padding-top: 0;
      .view-empty {
        min-height: 400px;
      }
    }
  }
  .page-user-api-tokens- #block-system-main {
    margin-left: 0;
  }
  .account-settings-tab .region-content #block-system-main {
    min-height: 522px;
  }
  #edit-credit-card {
    .commerce-credit-card-expiration {
      display: inline-block;
      margin-right: 15px;
    }
  }
  .page-user-credit-card #block-system-main {
    padding-top: 40px;
    min-height: 400px;
  }
  #blimp-zendesk-ticket-form .form-group {
    width: 60%;
  }
  #edit-priority-wrapper.form-group {
    float: right;
    width: 30%;
    .form-group {
      width: 100%;
    }
  }
  .page-user-api-tokens-create #block-system-main {
    margin-left: 0;
  }
  #edit-platform-plan .feature-list ul {
    position: relative;
    padding-left: 40%;
    min-height: 90px;
    li {
      float: left;
      width: 50%;
      &.base-feature-1,
      &.base-feature-2,
      &.base-feature-3 {
        position: absolute;
        left: 0;
        top: 0;
      }
      &.base-feature-2 {
        top: 30px;
      }
      &.base-feature-3 {
        top: 60px;
      }
    }
  }
  #edit-platform-plan .custom-field-wrapper .form-item .help-block {
    line-height: 55px;
    margin-bottom: 0;
  }
  #edit-platform-plan .custom-field-wrapper .form-item label {
    float: left;
  }
  #edit-platform-plan .custom-field-wrapper .form-item select {
    float: right;
    margin: 9px 20px 0 0;
  }
  .user-edit-platform #edit-platform-base,
  #edit-platform-plan .custom-field-wrapper {
    padding-right: 125px;
  }
  #edit-platform-plan .custom-field-wrapper .form-item-platform-customization-platform-agency-site .help-block {
    position: absolute;
    left: 100px;
    top: 0;
  }
  .user-edit-platform #final-total,
  .user-edit-platform .tax-info {
    clear: right;
    float: right;
    width: 410px;
  }
  #block-universal-navbar-universal-navbar-account {
    float: right;
  }
  #block-universal-navbar-universal-navbar-menu {
    float: left;
  }
  .universal-navbar > li,
  .item-list .universal-navbar > li {
    display: inline-block;
  }
  .navbar-fixed-top {
    position: fixed;
  }
  .universal-navbar ul.dropdown,
  .universal-navbar-account-links ul.dropdown {
    position: absolute;
  }
  html body.admin-menu {
    margin-top: 29px !important;
  }
  body.navbar-is-fixed-top,
  body.admin-menu.navbar-is-fixed-top {
    padding-top: 49px !important;
  }
  body.in-bitbucket-context {
    padding-top: 0 !important;
  }
  body.admin-menu .navbar-fixed-top {
    top: 28px;
  }
  .nav-tabs>li {
    float: left;
    margin-bottom: -1px;
    a {
      display: inline-block;
    }
  }
  .nav-tabs {
    > li {
      > a {
        color: @gray;
        padding: 0 15px 15px;
      }
      &:hover a {
        background: transparent;
        border: 2px solid @brand-primary;
        border-width: 0 0 2px 0;
        padding: 0 15px 13px;
        color: @gray;
      }
    }
    > li.active {
      > a,
      > a:hover,
      > a:focus {
        color: @gray;
        background: transparent;
        border: 2px solid @brand-primary;
        border-width: 0 0 2px 0;
        font-weight: 600;
        padding: 0 15px 13px;
        margin: 0 0 1px;
      }
    }
  }
  .vertical-tabs .nav-tabs > li > a {
    padding: 15px;
    border: none;
    &:hover,
    &:focus {
      padding: 15px;
      margin-bottom: 0;
      border: none;
    }
  }
  .vertical-tabs .nav-tabs > li.selected > a {
    border-width: 0 0 1px 0;
  }
  .view-platforms-user-view .view-header {
    float: left;
  }
  .view-platforms-user-view .view-filters {
    padding-right: 100px;
    float: right;
    clear: both;
  }
  .views-exposed-form .views-exposed-widget {
    float: left;
    margin-bottom: 0;
  }
  .views-widget-filter-bpa_platform_project_name_value {
    margin-right: 20px;
    width: 160px
  }
  .view-platforms-user-view .views-row:nth-child(2n) {
    margin-right: 0;
  }
  #views-exposed-form-platforms-user-view-page .form-item-owned {
    position: absolute;
    right: 100%;
    margin-right: 10px;
    margin-left: 0;
  }
}

// Standard
@media (min-width: 992px) {
  #edit-field-profile-picture,
  #edit-picture {
    width: 350px;
  }
  #user-profile-form,
  .user-billing-address,
  .user-ssh-keys {
    > div > fieldset,
    > div > .form-wrapper {
      margin-right: 430px;
    }
    > div > .field-type-cl-billing-pricing {
      margin-right: 0;
    }
  }
  .account-settings-tab {
    .tabs--secondary {
      width: 190px;
    }
    #block-system-main {
      margin-left: 189px;
    }
  }
  .page-user-security-tfa-app-setup,
  .page-user-api-tokens-create,
  .page-user-api-tokens- {
    #block-system-main {
      margin-left: 0;
    }
  }
  .page-user-api-tokens-create #block-system-main {
    padding: 0;
    background: transparent;
  }
  #blimp-user-billing-address-form {
    .panel-body,
    .field-name-field-company,
    .field-name-field-cc {
      margin-right: 330px;
    }
  }
  #edit-platform-plan .feature-list {
    width: 70%;
  }

  .view-platforms-user-view .view-filters {
    padding-right: 0;
    clear: right;
  }
  .view-platforms-user-view .views-row:nth-child(2n) {
    margin-right: 20px;
  }
  .view-platforms-user-view .views-row:nth-child(3n) {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  #blimp-user-billing-address-form {
    .panel-body,
    .field-name-field-company,
    .field-name-field-cc {
      margin-right: 430px;
    }
  }
}
