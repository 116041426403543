.error.form-item {
  padding: 10px;
}
input[type="file"],
input[type="text"],
input[type="password"],
select,
select.form-control,
fieldset,
fieldset.panel,
.resizable-textarea textarea,
.chosen-container-single .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-single {
  border-radius: 0;
  border-color: #e2e0e0;
  box-shadow: none;
  color: #888888;
  height: 38px;
  line-height: 36px;
  &:focus {
    color: @gray;
  }
}
select,
select.form-control {
  background-image: url("@{image-path}/caret-select.png");
  background-position: right center;
  background-repeat: no-repeat;
}
.chosen-container-single .chosen-single,
.chosen-container-multi .chosen-choices,
.chosen-container-active.chosen-with-drop .chosen-single {
  line-height: 1.428571429;
  height: 40px;
  padding: 6px 12px;
  background: #fff;
  background-image: none !important;
  border: 1px solid #e5e5e5;
  min-width: 100px;
  max-width: 100%;
  span {
    line-height: 36px;
  }
  div {
    width: 25px;
    b {
      background-image: none !important;
      background-position: left center;
      background-size: auto !important;
      width: 25px;
    }
  }
}
.chosen-container {
  max-width: 100%;
}
.chosen-container-multi .chosen-choices {
  min-height: 40px;
  padding: 0;
  background: #fff !important;
  .search-choice {
    background: @brand-light;
    border: none;
    box-shadow: none;
    span {
      line-height: 24px;
    }
    .search-choice-close {
      top: 10px;
    }
  }
  &:after {
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 38px;
  }
}
.chosen-container-single .chosen-search {
  input {
    line-height: 1.428571429;
    height: 32px;
    padding: 0 12px;
    border: 1px solid #e5e5e5;
    box-shadow: none;
  }
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-image: none !important;
}
.chosen-container.chosen-with-drop .chosen-drop {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  min-width: 100px;
  max-width: 100%;
}
.error {
  .form-control,
  .chosen-container-single .chosen-single,
  .chosen-container-active.chosen-with-drop .chosen-single {
    border-color: #f57f84;
    color: #f57f84;
  }
}
.btn-success,
.btn-primary,
.btn-info,
.btn-default {
  background: @brand-primary;
  border: none;
  border-radius: 0;
  color: #fff;
  &:hover {
    background: @link-hover-color;
    color: #fff;
    cursor: pointer;
  }
}
fieldset,
fieldset.panel,
.resizable-textarea textarea {
  height: auto;
  line-height: 1em;
}
select,
select.form-control,
.chosen-container-single .chosen-single  {
  -webkit-appearance: none;
  -moz-appearance:none;
  appearance:none;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  &:after {
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 38px;
  }
}
.chosen-container-single .chosen-single {
  background: #fff;
}
.chosen-container-multi.chosen-with-drop .chosen-choices:after,
.chosen-container-active.chosen-with-drop .chosen-single:after {
  content: "\f106";
}
@-moz-document url-prefix(){
  select,
  select.form-control {
    background: #fff;
  }
}
.form-actions {
  .btn {
    font-size: 12px;
    padding: 6px 20px;
  }
}
form {
  h2 {
    font-weight: 400;
    margin: 0 0 30px;
  }
  .progress {
    border-radius: 0;
    box-shadow: none;
    height: 5px;
    margin-bottom: 0;
    .progress-bar {
      box-shadow: none;
    }
  }
}
.form-required {
  color: #ee0e0e;
}
// Support Form.
a.return-link {
  color: #888;
  font-size: 11px;
  display: inline-block;
  font-style: italic;
  padding: 15px 0 15px 25px;
  background: transparent url("@{image-path}/icon-return.png") no-repeat left center;
  &:hover {
    text-decoration: none;
    color: #545353;
  }
}

label {
  font-weight: 400;
}
.page-user-api-tokens form #edit-token-bundle {
  label {
    font-weight: 700;
  }
  .description {
    font-style: italic;
  }
}
#blimp-zendesk-ticket-form {
  background: #fff;
  padding: 30px;
  h2 {
    font-size: 24px;
    font-weight: 400;
    margin-top: 0;
  }
  .form-group {
    margin-bottom: 30px;
  }
  .form-submit {
    position: relative;
  }
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
#submit-throbber {
  &.spinning {
    -webkit-animation: spin2 1s infinite linear;
    animation: spin 1s infinite linear;
  }
}

#edit-priority-wrapper.form-group {
  background: @brand-light;
  padding: 30px;
  .description-label {
    font-weight: 600;
  }
}
#edit-urgent,
#edit-high {
  font-style: italic;
  .description-label {
    font-style: normal;
  }
}

.form-item-ticket-description {
  textarea {
    background: #f3f3f3;
    border-color: #f3f3f3;
    color: @gray-dark;
    padding: 15px;
  }
  .grippie {
    display: none;
  }
}
// User Forms.
.account-settings-tab {
  .header-container {
    position: relative;
  }
  .tabs--secondary {
    padding-top: 10px;

  }
  #block-system-main {
    padding-top: 50px;
  }
}
.account-settings-tab #block-system-main form {
  background: #fff;
  label {
    font-weight: normal;
  }
  .form-type-password {
    max-width: 100%;
    width: 500px;
  }
}
#user-profile-form {
  position: relative;
}
#edit-field-platformsh-pricing-options {
  margin-right: 0;
  label {
    display: block;
  }
  .table-responsive {
    width: 100%;
    display: inline-block;
    overflow-x: inherit;
  }
  .chosen-container {
    width: auto !important;
  }
}
.page-user-edit {
  .confirm-parent,
  .password-parent,
  div.form-item div.password-suggestions {
    max-width: 100%;
  }
  .form-type-password-confirm {
    .form-type-password {
      width: auto;
      .form-control {
        padding-right: 0;
        max-width: 100%;
        width: 100%;
      }
    }
    .help-block {
      display: none !important;
    }
  }
  .password-strength,
  div.password-confirm {
    max-width: 40%;
    font-size: 12px;
  }
  .password-suggestions {
    display: none !important;
  }
  .form-type-password input.form-text {
    max-width: 55%;
  }
  .form-item-current-pass input.form-text {
    max-width: 100%;
  }
  #edit-cancel {
    background: transparent;
    border: none;
    color: @brand-primary;
    &:hover {
      background: transparent;
      text-decoration: underline;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .form-wrapper,
  .form-group {
    margin-bottom: 30px;
  }
  input[type="file"],
  input[type="text"],
  input[type="password"],
  select,
  select.form-control
  .chosen-container-single .chosen-single,
  .chosen-container-active.chosen-with-drop .chosen-single {
    color: #888888;
    height: 38px;
    line-height: 36px;
    &:focus {
      color: @gray;
    }
  }
  .display-name {
    color: #b0afaf;
  }
  #display-name-toggle {
    margin-left: 10px;
  }
  .account-names {
    display: flex;
    border: 0;
    padding: 0;
    > .form-item {
      margin: 0 5px;
    }
  }
}
.chosen-container-active.chosen-with-drop .chosen-single {
  padding-top: 0;
  padding-bottom: 0;
}
.page-user-api-tokens-create,
.page-user-api-tokens-edit,
.page-user-api-tokens-delete {
  .main-container .region-content #block-system-main {
    background: transparent;
    padding: 0;
  }
}
.page-user-api-tokens form {
  h2 {
    margin: 0;
  }
  h4 {
    margin: 0 0 30px;
  }
  .field {
    margin-bottom: 10px;
    .field-label {
      float: left;
      margin-right: 10px;
    }
  }
  #edit-token-value {
    font-size: 1.3em;
  }
}
#edit-field-profile-picture {
  background: @brand-light;
  padding: 20px 30px;
  label {
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #64696b;
    font-size: 12px;
  }
  input[type="file"] {
    border: none;
    background: transparent;
    padding-left: 0;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
    &:focus {
      box-shadow: none;
    }
  }
  .form-submit {
    background-color: @brand-primary;
    border-color: @brand-primary;
    color: #fff;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @link-hover-color;
      border-color: @link-hover-color;
    }
  }
}
#edit-picture {
  background: @brand-light;
  border: none;
  padding: 20px 30px;
  .panel-heading {
    background: transparent;
    border: none;
    text-transform: uppercase;
    color: #64696b;
    padding: 0;
    .panel-title {
      margin-bottom: 20px;
      font-size: 12px;
    }
  }
  .panel-body {
    padding: 0;
  }
  .user-picture img {
    max-width: 65px;
    max-height: 65px;
    border-radius: 65px;
    margin-bottom: 10px;
  }
  .form-type-file {
    label {
      font: 0/0 none;
    }
    input {
      line-height: 1em;
      background: transparent;
      border: none;
      height: auto;
      padding: 0;
      margin: 0;
    }
  }
}
.field-name-field-profile-picture {
 .image-preview img {
   border-radius: 65px;
 }
  .image-widget-data {
    line-height: 64px;
  }
}
#sshkey-edit-form,
#sshkey-delete-form,
#contact-personal-form,
.api-token-form,
#tfa-basic-setup-form,
#tfa-basic-disable-form,
.auth-tfa-form {
  > div {
    background: #fff;
    padding: 30px;
  }
}

#stripe_sepa_debit {
  #iban-element {
    background: #f5f7fa;
    padding: 0 16px;
    width: 336px;

    &.StripeElement--focus {
      border: 1px solid #1664ff !important;
      box-shadow: 0 0 2px #1664ff;
      padding: 0 15px;
      outline: none;
      background: #fff;
    }

    &.StripeElement--invalid {
      background-color: #fff !important;
      border: 1px solid #ec4646 !important;
    }
  }

  #mandate-acceptance {
    margin: 15px 0;
    border: 1px solid #aaa;
    padding: 15px;
  }

  #error-message {
    color: #ec4646;
    margin: 15px 0;
  }
}

// User Credit cards.
// CC Edit form.
#commerce-stripe-cardonfile-create-form {
  padding-top: 0;
  > div {
    background: #f2f2f2;
    min-width: 50%;
    display: inline-block;
  }
  .stripe-exp-month,
  .stripe-exp-year {
    width: auto;
    min-width: 55px;
  }
  .stripe-code {
    width: auto;
    min-width: 55px;
  }
  .commerce-credit-card-expiration {
    .form-type-select {
      margin-right: 0;
    }
  }
  .action-links {
    padding-top: 30px;
    clear: left;
  }
  .form-submit {
    margin-right: 20px;
  }
  .form-required {
    display: none;
  }
  .checkout-processing {
    padding-right: 13px !important;
    margin-right: 6px;
    margin-left: -13px;
    background: url("@{image-path}/status-active.gif") right center no-repeat;
  }
}

#payment-container {
  background: #f2f2f2;
  margin-left: 0;
  padding: 0;
  max-width: 500px;
  .payment-wrapper {
    padding: 20px;
    input.form-text {
      min-width: inherit;
      max-width: 100%;
      width: 100%;
      font-size: 16px;
      padding-left: 10px;
    }
  }
  .payment-header {
    .title {
      color: #666;
      font-size: 14px;
      font-weight: 600;
      padding: 0 0 0 35px;
      line-height: 28px;
      margin-bottom: 10px;
      position: relative;
      .fa {
        text-align: center;
        border: 2px solid;
        border-radius: 25px;
        height: 25px;
        width: 25px;
        line-height: 22px;
        font-size: 16px;
        position: absolute;
        top: 1px;
        left: 1px;
      }
    }
    .subtitle {
      color: #666;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  .commerce_payment,
  .fieldset-wrapper {
    padding: 0;
  }
  .form-item-commerce-payment-payment-details-cardonfile {
    width: 100%;
  }

  .stripe-owner {
    color: #000;

    &::placeholder {
      color: black;
    }
  }

  #card-element {
    background: #f5f7fa;
    padding: 0px 10px;
  }

  #error-message {
    color: #C00;
    margin-top: 12px;
  }
}

.page-user-credit-card {
  #block-system-main {
    background: #fff;
  }
}
.page-user-credit-card-edit-card {
  #edit-credit-card {
    display: inline-block;
    width: 100%;

    .form-item-credit-card-code {
      width: 130px;
      display: inline-block;
      label {
        display: none!important;
      }
    }
  }
  .commerce-credit-card-expiration {
    float: left;
    width: 250px;
    .form-item-credit-card-exp-month {
      width: 125px;
      display: inline-block;
    }
    .commerce-month-year-divider {
      display: inline-block;
    }
    .form-item-credit-card-exp-year {
      width: 65px;
      display: inline-block;
    }
  }
}
#cards {
  font-size: 11px;
  color: #666;
  line-height: 32px;
  margin-bottom: 20px;
  span {
    font: 0/0 none;
    color: transparent;
    &.mc,
    &.visa,
    &.amex {
      background: transparent url("@{image-path}/icon-mc.png") no-repeat center center;
      height: 32px;
      width: 52px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }
    &.visa {
      background-image: url("@{image-path}/icon-visa.png");
    }
    &.amex {
      background-image: url("@{image-path}/icon-amex.png");
    }
    &.message {
      font-family: @font-family-sans-serif;
      font-size: 11px;
      color: #666;
      line-height: 32px;
      height: 32px;
      display: inline-block;
      clear: left;
    }
  }
  &.country-us {
    span {
      &.dc,
      &.jcb,
      &.discover {
        background: transparent url("@{image-path}/icon-discover.png") no-repeat center center;
        height: 32px;
        width: 52px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
      &.jcb {
        background-image: url("@{image-path}/icon-jcb.png");
      }
      &.dc {
        background-image: url("@{image-path}/icon-dc.png");
      }
    }
  }
}
.page-user #payment-details {
  .form-item label {
    margin-bottom: 5px;
  }
  .form-item label .help {
    color: #707070;
    font-size: 80%;
  }
  .commerce-credit-card-expiration {
    float: left;
    clear: left;
    .commerce-month-year-divider {
      display: none;
    }
    .form-item {
      float: left;
      margin-top: 0;
    }
    .form-item-commerce-payment-payment-details-credit-card-exp-month,
    .form-item-credit-card-exp-month {
      width: 125px;
    }
    .form-item-commerce-payment-payment-details-credit-card-exp-year,
    .form-item-credit-card-exp-year {
      width: 65px;
      margin-left: 10px;
    }
    label {
      float: left;
      color: #acacac;
      line-height: 36px;
      height: 36px;
      width: 40px;
      margin-right: 5%;
      .form-required {
        font: 0/0 none;
      }
    }
    select.stripe-exp_month {
      margin-left: 40px;
      width: 65px;
    }
  }
}
#blimp-user-billing-address-form {
  fieldset,
  fieldset.panel {
    border: none;
    color: #333;
  }
  .panel-body,
  .field-name-field-company {
    padding: 0;
  }
  .name-block .form-item {
    float: none;
    margin-right: 0;
  }
  .btn-info {
    background: @brand-primary;
  }
  .field-type-vat-number input {
    width: auto;
  }
}

// Access denied login.
.page-error-denied,
.page-error-not-found {
  #user-login {
    max-width: 300px;
    margin: 30px auto;
  }
  #openid-connect-login-form {
    text-align: center;
    margin: 30px auto;
  }
}
.page-user-security-tfa-app-setup {
  #block-system-main {
    margin-left: 0;
  }
  #tfa-basic-setup-form,
  .auth-tfa-form {
    .form-type-password,
    .form-item-code {
      max-width: 300px;
    }
  }
}
.page-system-tfa {
  #tfa-form {
    max-width: 360px;
    margin: 30px auto;
  }
  .form-actions {
    padding-top: 20px;
  }
  #edit-fallback {
    color: #858585;
    background: transparent;
    padding: 0;
    text-transform: none;
    font-style: italic;
    margin-left: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Edit platform.sh project form.
#edit-platform-plan {
  padding: 45px 0 100px;
  .delete {
    display: inline-block;
    margin-left: 15px;
    a.btn-danger {
      background: transparent;
      color: @gray-dark;
      border-color: transparent;
      &:hover {
        color: @btn-danger-bg;
        font-weight: bold;
      }
    }
  }
  .form-title {
    margin: 0 0 25px;
    font-size: 24px;
  }
  .upgrade-instructions {
    margin-bottom: 25px;
    color: #6e6d6d;
    padding-top: 30px;
    .required {
      color: #df2911;
      margin-right: 5px;
    }
  }
  fieldset.panel {
    border: none;
    background: transparent;
    margin-bottom: 0;
    position: relative;
    color: #000;
    .cost,
    .total {
      color: #000;
      position: absolute;
      top: 0;
      right: 0;
      line-height: 55px;
      font-size: 24px;
    }
  }
  .panel-heading {
    display: none;
  }
  .panel-body {
    padding: 0;
  }
  .production-wrapper {
    float: left;
    line-height: 40px;
  }
  .form-item-platform-base-platform-base-option {
    float: right;
    width: 200px;
    max-width: 100%;
  }
  .feature-list {
    clear: both;
    font-size: 12px;
    label {
      margin-bottom: 25px;
      text-transform: uppercase;
      color: #7d7d7d;
      font-size: 11px;
    }
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      background: transparent url("@{image-path}/icon-check-blue.png") no-repeat 0 2px;
      color: @brand-primary;
      list-style: none;
      padding-left: 30px;
      line-height: 20px;
      margin-bottom: 10px;
      &.base-feature-1,
      &.base-feature-2,
      &.base-feature-3 {
        color: #000;
        background: transparent url("@{image-path}/icon-check.png") no-repeat 0 2px;
      }
    }
  }
  .custom-field-wrapper {
    position: relative;
    padding-right: 65px;
    padding-left: 55px;
    .required {
      position: absolute;
      right: 130px;
      top: 10px;
      color: #df2911;
    }
    .form-item {
      padding: 0;
      background: #fff;
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
      &:before {
        content: " ";
        height: 55px;
        width: 55px;
        background: #383838;
        position: absolute;
        left: 0;
        top: 0;
      }
      &.form-item-platform-customization-platform-option-environment:before {
        background: #383838 url("@{image-path}/icon-environments.png") no-repeat center center;
      }
      &.form-item-platform-customization-platform-option-storage:before {
        background: #383838 url("@{image-path}/icon-storage.png") no-repeat center center;
      }
      &.form-item-platform-customization-platform-option-dev-license:before {
        background: #383838 url("@{image-path}/icon-user.png") no-repeat center center;
      }
      label {
        line-height: 55px;
        margin: 0;
        padding-left: 25px;
      }
      select {
        width: 100px;
        margin: 9px 0 10px 20px;
        font-size: 18px;
      }
      select[disabled="disabled"] {
        background: transparent;
        border: none;
      }
      .help-block {
        float: left;
        margin: 0 0 10px;
        padding-left: 20px;
        color: #7d7d7d;
      }
    }
  }
  .custom-field-wrapper .form-type-checkbox {
    &:before {
      left: -55px;
      background: #383838 url("../images/cog.svg") no-repeat center center;
      background-size: 24px auto;
      line-height: 55px;
      text-align: center;
    }
  }
  .checkbox {
    position: relative;
    display: inline-block;
    label {
      width: 100%;
    }
    &.form-disabled,
    &.form-disabled label,
    &.form-disabled label span {
      opacity: .8;
      cursor: not-allowed;
    }
    &.form-disabled input {
      cursor: not-allowed;
    }
  }
  input[type="checkbox"] + span {
    cursor: pointer;
    float: left;
  }
  input[type="checkbox"]:disabled + span {
    cursor: not-allowed;
  }
  input[type="checkbox"] {
    opacity: 0;
    text-indent: -99999px;
  }
  input[type="checkbox"] + span::before {
    content: "";
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 20px; /* LTR */
    height: 20px;
    width: 20px;
    background: #cfcfcf;
  }
  input[type="checkbox"]:disabled + span::before {
    cursor: not-allowed;
  }
  input[type="checkbox"]:focus + span::before {
    outline: 0;
    box-shadow: 0 0 3px #36a2f6;
  }
  .disabled input[type="checkbox"] + span::before {
    opacity: .4;
  }
  input[type="checkbox"]:checked + span {
    &:before {
      top: 17px;
      right: 20px; /* LTR */
      background: @brand-primary url("../images/check.svg") no-repeat center center;
      background-size: 80% auto;
      line-height: 19px;
      text-indent: 2px;
      content: " ";
      display: inline-block;
      position: absolute;
      height: 20px;
      width: 20px;
    }
  }
}
.user-edit-platform {
  #edit-platform-base {
    padding: 0 65px 0 55px;
    .panel-body {
      padding: 10px 20px 10px 25px;
      background: #fff;
      margin-bottom: 10px;
      &:before {
        content: " ";
        height: 55px;
        width: 55px;
        background: #383838 url("@{image-path}/icon-platform-white.png") no-repeat center center;
        background-size: 20px auto;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  #final-total {
    font-size: 24px;
    position: relative;
    padding-top: 25px;
    margin-bottom: 10px;
    .ajax-progress {
      position: absolute;
      right: 100%;
      top: 25px;
      padding-right: 20px;
      .throbber {
        height: auto;
        width: auto;
        background: transparent;
        float: none;
      }
    }
  }
  .total-title,
  .total-wrapper,
  .number,
  .extra {
    display: inline-block;
    line-height: 55px;
    color: #000;
  }
  .total-title span {
    color: #ee0e0e;
    font-size: 16px;
    line-height: 44px;
    vertical-align: text-bottom;
  }
  .tax-info {
    color: #6e6d6d;
    line-height: 1.25em;
    margin-bottom: 30px;
    span {
      color: #ee0e0e;
    }
  }
  .total-wrapper {
    float: right;
    font-weight: 700;
  }
  .action-wrapper {
    clear: both;
    font-size: 12px;
    .cancel {
      margin-left: 30px;
      text-transform: uppercase;
    }
    .btn {
      font-size: 12px;
      padding: 8px 20px;
    }
    &.hidden {
      display: none;
    }
  }
  #edit-platform-currency-switcher {
    position: absolute;
    left: -9999px;
  }
}

// Confirm platform.sh deletion form.
#confirm-delete {
  padding-top: 30px;
  h4 {
    margin-top: 30px;
    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  ul {
    margin: 30px 0;
  }
  .form-actions {
    button, a {
      float: right;
      margin-left: 20px;
    }
    a {
      line-height: 31px;
    }
  }
}
.page-user {
  .addressfield-container-inline,
  .street-block {
    margin-bottom: 15px;
  }
}
.page-user-address label {
  display: block;
}
.view-filters select {
  padding-right: 30px;
}
#views-exposed-form-platforms-user-view-page {
  position: relative;
  .form-item-owned {
    color: #000;
    width: 185px;
    line-height: 37px;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 10px;
    input[type=checkbox] {
      top: 8px;
      margin-left: -18px;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-indent: 20px;
      color: #000;
      &:hover {
        text-decoration: none;
      }
      &:focus {
        text-decoration: none;
        opacity: .6;
        outline: none;
      }
    }
  }
  .chosen-container .chosen-single {
    height: 38px;
  }
}
.views-exposed-form .views-exposed-widget {
  float: none;
  margin-bottom: 25px;
}
#blimp-user-validation {
  padding-top: 20px;
  .form-actions {
    padding-top: 20px;
  }
}
.logged-in .universal-navbar-account-links > li > a {
  background: none;
  &:after {
    content: "\f107";
    font-family: 'FontAwesome';
    font-size: 18px;
    position: absolute;
    top: 3px;
    right: 5px;
  }
}
