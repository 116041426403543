// Insert your styling here.
#page-header {
  position: relative;
}

.main-container {
  border-top: 1px solid @body-bg;
}

.block--low-contrast {
  padding: 30px;
  background: #FFF;

  table {
    width: 100%;
    table-layout : fixed;
  }
}

.messages {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  .message-wrapper {
    ul {
      text-align: left;
      margin: 0;
      padding-left: 20px;
    }
  }
  .message-content {
    padding: 40px 0 40px 50px;
    color: @gray-dark;
    font-size: 16px;
    position: relative;
    .fa {
      font-size: 22px;
      line-height: 26px;
      position: absolute;
      left: 0;
      border: 3px solid;
      border-radius: 30px;
      top: 35px;
      padding: 2px;
      height: 32px;
      width: 32px;
      text-align: center;
    }
  }
  .close {
    margin-left: 10px;
  }
  &.error {
    background: @brand-danger-bg;
    .message-content {
      a, a:hover, .fa {
        color: @brand-danger;
      }
    }
  }
  &.status {
    background: @brand-success-bg;
    .message-content {
      a, a:hover, .fa {
        color: @brand-success;
      }
    }
  }
  &.warning {
    background: @brand-warning-bg;
    .message-content {
      a, a:hover, .fa {
        color: @brand-warning;
      }
    }
  }
  &.info {
    background: @brand-info-bg;
    .message-content {
      a, a:hover, .fa {
        color: @brand-info;
      }
    }
  }
}

.user-flag-cc, .user-flag-unverified {
  &:before {
    content: "\f283";
    color: @brand-danger;
    font-size: 16px;
    font-family: FontAwesome;
  }
}

.user-flag-unverified:before {
  content: "\f235";
}

// Cookie notice.
#sliding-popup.sliding-popup-bottom {
  .popup-content {
    #popup-text {
      margin: 12px 0;
      p {
        font-weight: 400;
        text-align: left;
      }
    }
    #popup-buttons button {
      background: @brand-primary;
      border: none;
      border-radius: 0;
      box-shadow: none;
      color: #fff;
      text-shadow: none;
      text-transform: uppercase;
      font-weight: 400;
      padding: 8px 20px;
      margin-bottom: 1em;
      &:hover {
        background: @link-hover-color;
      }
      &.find-more-button {
        background: transparent;
        color: @brand-primary;
        text-transform: none;
        padding: 8px 0;
        &:hover {
          text-decoration: underline;
          background: transparent;
        }
      }
    }
  }
}

// User profile - secondary tabs under 'Account Settings'.
.account-settings-tab {
  #block-system-main {
    padding-top: 40px;
  }
}

.user-order-full {
  #block-system-main {
    padding-top: 20px;
  }
}

.view-platforms-user-view {
  padding-top: 30px;
  .add-service {
    margin-bottom: 30px;
    a {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .view-footer {
    clear: left;
    padding-bottom: 30px;
  }
}

// Default for 'no platform' display.
.service-info {
  padding-top: 30px;
  .service-item {
    margin: 0 40px 40px 0;
    background: #fff;
    width: 420px;
    max-width: 100%;
  }
  .service-header {
    background: #00adef;
    height: 140px;
    box-sizing: border-box;
    padding: 20px 35px;
    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 100px;
      margin: 0;
    }
  }
  .service-body {
    padding: 35px 25px;
    color: @gray-dark;
    font-size: 14px;
    a {
      margin-top: 30px;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
    }
  }
  .service-support .service-header {
    background: #00adef;
  }
  .service-platform .service-header {
    background: #000;
    h3 {
      padding-right: 30px;
      display: inline-block;
      background: transparent url("@{image-path}/icon-platform-white.png") no-repeat right 30px;
    }
  }
  .service-platform .service-body {
    a {
      color: #000;
    }
  }
}

.view-platforms-user-view {
  > .text-center {
    clear: both;
  }
  .views-field-status {
    display: none;
  }
  .views-row {
    background: #fff;
    color: #000;
    font-weight: 400;
    margin-right: 20px;
    margin-bottom: 25px;
    padding: 20px;
    width: 300px;
    height: 175px;
    position: relative;
    a {
      color: @gray-dark;
      .platform-title {
        color: @gray-dark;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &.suspended,
    &.Suspended {
      background: #fce9e9;
      .plan {
        border-bottom: 1px solid @brand-danger;
      }
      .views-field-status {
        display: block;
        position: absolute;
        top: 100px;
        color: @brand-danger;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        a {
          text-transform: none;
          font-size: 12px;
          font-weight: 600;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .platform-title,
      .plan,
      .owner {
        color: @brand-danger;
      }
      .error {
        display: none;
      }
    }
  }
  .platform-title {
    margin: 0;
    display: block;
    font-size: 18px;
    font-weight: 200;
    color: @gray-dark;
    margin-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3em;
    white-space: nowrap;
  }
  .plan {
    color: #7f7e7e;
    padding-bottom: 25px;
    margin-bottom: 28px;
    border-bottom: 1px solid #eeeded;
  }
  .usage-item {
    display: inline-block;
    width: 32%;
    position: relative;
    label {
      width: 100%;
    }
    span {
      font-size: 16px;
      padding-top: 2px;
    }
    label {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      color: #bababa;
      font-size: 12px;
    }
    &:hover {
      label {
        display: inline-block;
      }
    }
    .fa {
      color: #bababa;
    }
  }
  .usage-storage {
    text-align: center;
  }
  .usage-users {
    text-align: right;
  }
  .edit-link {
    .edit-dropdown {
      display: inline-block;
      padding: 30px 0 0 0;
      height: 20px;
      overflow: hidden;
      position: absolute;
      top: 15px;
      right: 15px;
      color: #bababa;
      cursor: pointer;
      &:before {
        position: absolute;
        top: 5px;
        right: 25px;
        font-size: 22px;
      }
      &:after {
        content: "\f107";
        font-family: 'FontAwesome';
        position: absolute;
        top: 6px;
        right: 5px;
        line-height: 20px;
        font-size: 20px;
      }
      &:hover {
        overflow: visible;
        text-decoration: none;
        height: auto;
        color: @brand-primary;
      }
      a {
        display: block;
        width: 100%;
        color: #fff;
        padding: 6px 10px;
        background-color: @gray-dark;
        border: 2px solid @gray-dark;
        &:hover {
          color: #fff;
          background: @brand-primary;
          cursor: pointer;
        }
        &.delete {
          color: #bababa;
          margin-bottom: 0;
          &:hover {
            color: #fff;
            background: #bababa;
          }
        }
      }
    }
    a.update-payment,
    span.contact-owner {
      color: #363636;
      position: absolute;
      bottom: 40px;
      text-transform: none;
      font-size: 12px;
      font-weight: 600;
      &:hover {
        color: #363636;
        text-decoration: underline;
      }
      &:after {
        content: "\f0da";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-left: 4px;
      }
    }
    span.contact-owner:hover {
      text-decoration: none;
    }
    span.contact-owner:after {
      content: "";
    }
    span.owner {
      color: #363636;
      position: absolute;
      bottom: 22px;
      text-transform: none;
      font-size: 12px;
      display: none;
      opacity: .8;
    }
  }
  .owner {
    color: @brand-primary;
    position: absolute;
    top: 64px;
  }
  .views-row:hover {
    a {
      .platform-title {
        color: @brand-primary;
      }
      .plan {
        color: @brand-primary;
        border-bottom: 1px solid @brand-primary;
      }
    }
    .edit-link {
      display: inline-block;
    }
  }
  .views-row.suspended:hover,
  .views-row.Suspended:hover {
    a {
      .platform-title {
        color: @brand-danger;
      }
      .plan {
        color: @brand-danger;
        border-bottom: 1px solid @brand-danger;
      }
    }
    .edit-link a:hover {
      color: @brand-danger;
    }
    span.owner {
      display: inline-block;
    }
  }
  .view-content,
  .view-empty {
    clear: both;
  }
  .total {
    font-size: 18px;
    font-weight: 100;
    .number {
      font-weight: 400;
    }
  }
}

// User orders.
.page-user-orders {
  h3 {
    color: #a4a4a4;
    font-size: 18px;
  }
  .entity .content {
    border: 0;
    padding: 0;
  }
  .view-footer h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
  .edit-order-link {
    text-align: right;
  }
  .view-blimp-user-orders > .attachment-before > .view > .view-content {
    table {
      margin: 0;
      &.subtotal-wrapper {
        margin-bottom: 10px;
      }
    }
    th {
      padding: 10px 15px;
    }
  }
  .view-blimp-user-orders > .view-content {
    background: #fff;
    padding: 30px;
    th {
      background: transparent;
      padding: 10px 5px;
      a {
        color: @gray-dark;
      }
    }
    td {
      padding: 5px 15px;
      table td {
        padding: 0 10px;
        font-size: 1em;
      }
      &.views-field-commerce-unit-price td {
        padding: 0;
      }
    }
    &.commerce-price-formatted-components {
      td {
        border-bottom: 0;
        padding: 10px 15px;
      }
    }
    .field-name-commerce-customer-billing,
    .field-name-commerce-customer-eu-vat-rc {
      display: block;
      margin-bottom: 20px;

      .field-label {
        border-bottom: 1px solid #CCCCCC;
        font-size: 16px;
        font-weight: 400;
      }
      .field-items {
        margin-top: 10px;
        .field-label {
          border-bottom: 0;
          display: inline;
          float: left;
          font-size: 100%;
          font-weight: 400;
          margin-bottom: 0;
        }
        strong {
          font-weight: 400;
        }
      }
    }
  }
  .view-platform-line-items {
    .table-striped>tbody>tr:nth-of-type(odd),
    .table-hover>tbody>tr:hover {
      background: transparent;
    }
  }
}

.current-order,
.current-order-full {
  .view-header,
  .view-content,
  .view-footer {
    background: #fff;
  }
}

.page-user-orders .current-order {
  padding: 0;
  .view-header {
    padding: 10px 30px;
  }
  .view-content {
    padding: 0 30px;
  }
  .view-header h3,
  .view-footer h3 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
  }
  .view-footer h3 {
    margin-top: 30px;
  }
  > .view-content > .views-table {
    margin-bottom: 0;
    background: #fff;
    border: 1px solid #fff;
    > thead th {
      background: transparent;
      font: 0/0 none;
      padding: 0;
      border: none;
      margin: 0;
      height: 0;
    }
    > tbody > tr,
    > tbody > tr > td {
      background: transparent;
      border: none;
      &:hover,
      &:hover td {
        background: transparent;
      }
    }
    > tbody > tr.views-row-first td {
      border-top: none;
    }
    > tbody > tr > td {
      font-size: 16px;
      font-weight: 500;
      padding: 10px 10px 30px;
      .label {
        color: @gray-dark;
        padding: 0;
      }
      &.views-field-type {
        position: relative;
        .links {
          position: absolute;
          bottom: 0;
          left: 15px;
          display: inline-block;
          a {
            display: inline-block;
          }
        }
      }
    }
    tr.views-row-active {
      background: #edf9fe !important;
      td {
        border-bottom: none;
      }
    }
    tr.even,
    tr.odd {
      background: transparent;
    }
    tr.megarow {
      border: 1px solid #eee;
      border-width: 0 1px 1px 1px;
      > td {
        padding: 0;
        border: none;
      }
      .views-megarow-content {
        border: none;
        border-radius: 0;
        padding-top: 0;
      }
      .megarow-header {
        background: transparent;
        padding: 0;
        position: relative;
        .megarow-title {
          display: none;
        }
        a.close {
          text-indent: 0;
          width: auto;
          height: auto;
          padding: 10px 10px 10px 30px;
          position: absolute;
          bottom: 100%;
          background: #fff url("@{image-path}/icon-less.png") no-repeat 5px center;
          font-size: 14px;
          font-width: 500;
          opacity: 1;
          color: @brand-primary;
        }
      }
    }
    .label {
      font-size: 12px;
    }
    .views-field-commerce-order-total .label {
      text-align: right;
      float: right;
      clear: right;
    }
    .cost {
      font-size: 24px;
      font-weight: 300;
      float: right;
      clear: right;
      text-align: right;
    }
  }
  .view-footer {
    .message {
      text-align: right;
      color: #666;
      font-size: 12px;
      padding-top: 5px;
    }
  }
}

// User orders.
.page-user-orders {

  .region-content .content {
    table {
      width: 100%;
    }
    .field-name-commerce-customer-billing,
    .field-name-commerce-customer-eu-vat-rc {
      display: block;
      margin-bottom: 20px;

      .field-label {
        border-bottom: 1px solid #CCCCCC;
        font-size: 16px;
        font-weight: 400;
      }
      .field-items {
        margin-top: 10px;
        .field-label {
          border-bottom: 0;
          display: inline;
          float: left;
          font-size: 100%;
          font-weight: 400;
          margin-bottom: 0;
        }
        strong {
          font-weight: 400;
        }
      }
    }
  }
  ul.action-links {
    padding-top: 5px;
    position: absolute;
    top: 100%;
    z-index: 9;
    li {
      list-style: none;
      a {
        text-decoration: none;
      }
    }
  }
}

.page-user-orders .current-order {
  padding-bottom: 0 !important;
  padding-top: 10px;
  margin-bottom: 0;
  .view-header h3 {
    margin-bottom: 0;
  }
  > .view-content > .views-table {
    tr.even,
    tr.odd,
    tr.even:hover,
    tr.odd:hover {
      background: #f2f2f2;
      border: 1px solid #eee;
    }
    margin-bottom: 0;
    &.active tbody tr,
    &.active tbody tr:hover {
      background: #d6f2fd;
      border: 1px solid @brand-primary;
      border-bottom: none;
    }
    > thead th {
      background: transparent;
      font: 0/0 none;
      padding: 0;
    }
    > tbody > tr,
    > tbody > tr > td {
      background: transparent;
      border-bottom: none;
      &:hover,
      &:hover td {
        background: transparent;
      }
    }
    > tbody > tr > td {
      font-size: 16px;
      font-weight: 500;
    }
    tr.views-row-active {
      background: #f2f2f2 !important;
      td {
        border-bottom: none;
      }
    }
    .label {
      font-size: 12px;
    }
    .views-field-commerce-order-total .label {
      text-align: right;
      float: right;
      clear: right;
    }
    .cost {
      font-size: 24px;
      font-weight: 300;
      float: right;
      clear: right;
      text-align: right;
    }
  }
  .view-footer {
    .message {
      text-align: right;
      color: #666;
      font-size: 12px;
      padding-top: 5px;
    }
  }
}

.logged-in.page-user .current-order .view-content > table > tbody > tr > td {
  padding: 10px 10px 40px;
}

.page-user-orders .current-order-full {
  padding: 0;
  position: relative;
  margin: 0;
  > .view-content {
    padding: 0 30px;
    display: none;
    > .views-row {
      border: 1px solid @brand-primary;
    }
  }
  .view-footer {
    padding: 0 30px;
  }
  .entity-commerce-order {
    h2 {
      font: 0/0  none;
      padding: 0;
      margin: 0;
    }
  }
  #order-toggle {
    position: absolute;
    width: auto;
    height: auto;
    bottom: 100%;
    margin: 0 0 -1px 36px;
    cursor: pointer;
    padding: 10px 10px 10px 30px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1em;
    &.close {
      color: @brand-primary;
      font-weight: 300;
      border: 1px solid @brand-primary;
      border-width: 1px 1px 0 1px;
      background: #fff;
      font-size: 14px;
      opacity: 1;
      line-height: 1em;
    }
    &:before {
      position: absolute;
      left: 6px;
      top: 10px;
      font-size: 20px;
    }
  }
  .view-footer {
    .message {
      text-align: right;
      padding-top: 10px;
    }
    h3 {
      padding-top: 30px;
    }
  }
}

/* User Platform Orders */
.view-platform-user-view .view-empty {
  font-size: 16px;
}

.page-user {
  .entity-commerce-order {
    background: #fff;
    padding: 30px;
    font-size: 12px;
  }
  .field-name-commerce-line-items {
    color: #666;
    caption {
      font-size: 14px;
      font-weight: bold;
      color: #000;
    }
    th {
      border-bottom: 1px solid #eee;
      padding: 5px 15px;
      &.views-field-commerce-total {
        text-align: right;
        padding-right: 15px;
      }
    }
    .component-type-commerce-price-formatted-amount {
      td {
        font-weight: bold;
      }
    }
    td {
      padding: 5px 15px;
      &.views-field-line-item-title {
        width: 50%;
      }
      &.views-field-nothing {
        width: 20%;
      }
      &.views-field-commerce-total {
        text-align: right;
        padding-right: 15px;
        td.views-field-line-item-title {
          .title {
            display: none;
          }
        }
      }
      .views-table tr.platform-header {
        &.views-row-last {
          border-bottom: none;
        }
        td {
          font-size: 14px;
          font-weight: 500;
          padding: 10px 5px;
        }
        td.views-field-line-item-title {
          font-size: 14px;
          font-weight: 500;
          .title {
            color: #000;
            font-size: 18px;
            font-weight: 700;
            display: inline-block;
          }
        }
        td.views-field-commerce-total {
          font-weight: 600;
        }
      }
      .commerce-price-savings-formatter-prices {
        margin: 0;
        td {
          padding: 0;
          text-align: left;
          background: transparent;
          border: none;
        }
        tr td {
          font-size: 1em;
        }
        .commerce-price-savings-formatter-price .price-amount {
          font-size: inherit;
          font-weight: 600;
          color: #000;
        }
      }
      .views-table .views-field-commerce-total,
      .views-table tr.platform-header .views-field-commerce-total {
        .commerce-price-savings-formatter-prices {
          td {
            text-align: right;
          }
        }
      }
    }
    .views-table tbody {
      border-top: none;
      border-bottom: none;
    }
    .subtotal-wrapper {
      td {
        padding: 0 10px;
      }
      > tbody > tr > td {
        padding: 0 5px;
      }
      .commerce-price-formatted-components {
        text-align: left;
        background: transparent;
        border: none;
        .component-type-commerce-price-formatted-amount {
          background: #f9f9f9;
          border-bottom: 1px solid #ccc;
          td {
            padding: 5px 10px;
            color: #323232;
          }
        }
      }
      .price-amount {
        font-weight: 600;
        color: #000;
      }
      &.commerce-price-savings-formatter-prices-count-2 .commerce-price-savings-formatter-price .price-amount {
        font-size: .9em;
      }
    }
    .views-table .views-field-commerce-total,
    .views-table tr.platform-header .views-field-commerce-total {
      .commerce-price-savings-formatter-prices {
        td {
          text-align: right;
          padding: 0;
        }
      }
    }
  }
  .views-table tbody {
    border-top: none;
    border-bottom: none;
  }
  .subtotal-wrapper {
    td {
      padding: 0 10px;
    }
    > tbody > tr > td {
      padding: 0 5px;
    }
    .commerce-price-formatted-components {
      margin-bottom: 30px;
      .component-type-commerce-price-formatted-amount {
        background: #f9f9f9;
        border-bottom: 1px solid #ccc;
        td {
          padding: 15px 10px;
          color: #323232;
        }
      }
    }

    .field-name-commerce-order-total {
      border-top: none;
      margin: 20px 0;
      table.commerce-price-formatted-components tbody {
        background: transparent;
        border: none;
        font-weight: bold;
        tr.component-type-commerce-price-formatted-amount {
          td {
            background: #d6f2fd;
            border: 1px solid #2aabe2;
            border: none;
            font-size: 14px !important;
          }
        }
      }
    }

  }
}

td.divider {
  padding: 5px;
  .subheader {
    padding-left: 15px;
    margin-bottom: 10px;
  }
  .message {
    text-transform: none;
    border-bottom: 1px solid #d6d6d6;
    text-align: center;
    margin-bottom: 20px;
  }
  .no-activity {
    text-transform: none;
    font-weight: 700;
    color: #666;
    padding-left: 15px;
    margin-bottom: 15px;
  }
}

.view-platform-line-items {
  td.divider .message {
    border-bottom: 1px solid #eee;
  }
}

.pane-blimp-vouchers-account-credit-summary-pane,
.view-blimp-partner-api-user-licenses .view-empty,
.view-user-support-tickets .view-content {
  background: #fff;
  font-size: 16px;
  padding: 30px;
}

.credit-card-empty .message-wrapper {
  padding: 0 40px;
}

.page-user-credit-card .credit-card-wrapper {
  background: @brand-light;
  color: #666c6e;
  display: inline-block;
  padding: 25px;
  position: relative;
  width: 325px;
  margin-left: 40px;
  .credit-card-type {
    margin-bottom: 10px;
    font-weight: 700;
  }
  .credit-card-update-link {
    font-size: 11px;
    position: absolute;
    top: 25px;
    right: 25px;
  }
}

.page-user-orders {
  .entity-commerce-order {
    .view-empty {
      padding: 0 30px;
      font-size: 16px;
    }
    .field-name-commerce-line-items,
    .field-name-commerce-order-total {
      margin-bottom: 20px;
    }
    .field-name-commerce-order-total {
      font-size: 1.2em;
      font-weight: 600;
      border-top: 2px solid #ddd;
      table td {
        background: #f9f9f9;
        border: none;
      }
      // Ensure order total style.
      table .component-type-commerce-price-formatted-amount td {
        border: 1px solid #00b4ff;
        border-width: 1px 0;
        background: #d6f2fd;
      }
    }
  }
  .view-blimp-user-orders > .view-empty {
    padding: 30px;
    background-color: #fff;
  }
}

.pane-blimp-vouchers-account-credit-summary-pane {
  h2 {
    font-weight: 400;
    font-size: 24px;
    margin: 0 0 30px;
  }
}

.account-settings-tab .region-content {
  #block-system-main {
    background: #fff;
    padding: 40px;
    .action-links {
      margin-bottom: 30px;
      a {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    table tr {
      border-bottom: 1px solid #eee;
    }
    table th,
    table th.active {
      background: transparent;
      color: @gray-dark;
      font-size: 80%;
      a {
        color: @gray-dark;
        img {
          display: inline-block;
        }
      }
    }
    table td {
      padding: 30px 10px;
      ul {
        margin: 0;
      }
    }
    .table-striped > tbody > tr > td.active,
    .table-striped > tbody > tr:hover > td,
    .table-striped > tbody > tr:nth-child(2n+1) > td,
    .table-striped > tbody > tr:nth-child(2n+1) > th {
      background: #fff;
    }
  }
}

.page-user-support .region-content #block-system-main {
  background: transparent;
  padding: 30px 0 0;
  .action-links {
    padding-left: 0;
    margin: 10px 0 15px;
    li.add {
      margin-bottom: 30px;
      a {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}

.page-user-support .region-content .view-empty {
  padding: 15px 0;
}

// User Licenses
.page-user-licenses {
  .view-blimp-partner-api-user-licenses {
    background: #fff;
    padding: 40px;
    min-height: 400px;
    table tr {
      border-bottom: 1px solid #eee;
    }
    table th,
    table th.active {
      background: transparent;
      color: @gray-dark;
      font-size: 80%;
      a {
        color: @gray-dark;
      }
    }
    table td {
      padding: 30px 10px;
      ul {
        margin: 0;
      }
    }
  }
}

.page-ssh-keys-user-delete #block-system-main {
  padding-top: 40px;
  min-height: 400px;
}

#admin-links {
  position: absolute;
  bottom: 13px;
  right: 0;
  ul.action-links {
    position: absolute;
    right: 0;
    top: 98%;
    display: none;
    background: #545353;
    min-width: 150px;
    border: 1px solid #545353;
    padding: 0;
    z-index: 9;
    li {
      padding: 0;
    }
    ul.action-links {
      position: relative;
      top: auto;
      margin: 0;
      padding: 0;
    }
    a {
      color: #fff;
      padding: 10px;
      display: inline-block;
      width: 100%;
      font-size: 12px;
      &:hover {
        background: @brand-primary;
        text-decoration: none;
      }
    }
  }
  &:hover ul.action-links {
    display: block;
  }
}

#admin-links-toggle {
  color: #a4a4a4;
  padding: 0 30px 13px 0;
  cursor: pointer;
  background: transparent;
  &:hover {
    border-bottom: 2px solid @brand-primary;
    color: @gray;
    text-decoration: none;
  }
  &:after {
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 20px;
  }
}

.nav-tabs > li {
  float: none;
  a {
    display: block;
  }
}

.page-node {
  .region-content {
    padding-top: 50px;
    .form-item {
      margin-bottom: 30px;
    }
  }
}

.page-error-not-found .return,
.page-error-denied .return {
  text-align: center;
  margin-bottom: 30px;
  a.btn:hover {
    color: #fff;
  }
}

// Invoices
.entity-commerce-invoice {
  padding-top: 22px;
  .subtotal-wrapper,
  .views-table {
    width: 100%;
    max-width: 100%;
  }
  .views-table {
    margin-bottom: 20px;
    th {
      background: #e1e1e1;
    }
    th, td {
      padding: @table-cell-padding;
    }
  }
  .views-field-commerce-total,
  .views-field-quantity,
  .views-field-commerce-unit-price {
    width: 100px;
    text-align: right;
  }
}

/**
 * Stripe Strong Customer Authentication (SCA) page
 */
body.page-stripe-sca {
  background: #f0f2f5;

  .header-container {
    padding: 20px 0 30px 0;
  }

  .page-wrapper {
    background: #f0f2f5;
  }

  h1.page-header {
    color: #000;
    font-weight: bold;
    margin: 0; padding: 0;
    line-height: 2;
    text-align: left;
    font-size: 24px;
  }
  .sca-button {
    margin: 30px 0;
    .form-submit {
      background: #4786ff;
      border-radius: 4px;
      width: 336px;
      max-width: 100%;
      height: 40px;
      padding: 0 16px;
    }
  }
}
