// Insert your styling here.
.navbar {
  background-color: #000;
  border: none;
  color: #8b8989;
  font-size: 12px;
  margin-bottom: 0;
  a {
    color: #8b8989;
    text-decoration: none;
    &:hover {
      color: #fff;
    }
  }
  .menu-toggle {
    float: right;
    margin-right: 18px;
  }
  p {
    margin: 0;
  }
}
#user-widget {
  .user-widget__toggle {
    height: 49px;
  }
  .user-widget__mega_menu__search__filter_bar {
    min-height: 52px;
  }
  .user-widget__mega_menu a {
    color: #fff;
    &:hover {
      color: rgba(0,174,239,1);
    }
  }
  .user-widget__mega_menu input[type=text] {
    color: #fff;
  }
}
#global-links,
#account-links {
  float: none;
  padding: 0;
  > li {
    position: relative;
    float: none;
    display: block;
    > a {
      text-transform: uppercase;
      line-height: 49px;
    }
  }
  a:hover {
    text-decoration: none;
  }
  ul.dropdown {
    background: #545353;
    padding: 1px;
    width: auto;
    min-width: 150px;
    display: inline-block;
    position: relative;
    top: 0;
    margin-bottom: 20px;
    li {
      padding: 0;
      display: block;
      float: none;
    }
    span.area {
      display: block;
      padding: 15px 10px 5px;
      color: #b6b5b5;
      font-size: 11px;
      text-transform: uppercase;
    }
    a {
      padding: 10px 10px 10px 20px;
      color: #fff;
      display: block;
      &:hover {
        background: @brand-primary;
      }
    }
    li p {
      color: #b6b5b5;
      padding: 10px;
      text-align: center;
      min-width: 175px;
      span {
        margin-bottom: 20px;
        display: inline-block;
        width: 100%;
        padding-top: 10px;
        line-height: 1.5em;
      }
      a.btn {
        background: @brand-primary;
        border-radius: 0;
        color: #fff;
        &:hover {
          background: @link-hover-color;
          cursor: pointer;
        }
      }
    }
  }
}
#account-links {
  float: none;
  text-align: left;
}
#global-links > li:hover > a {
  border-bottom: 2px solid @brand-primary;
  height: 49px;
}
#account-links > li > a {
  border: none;
  background: transparent url("@{image-path}/caret.png") no-repeat right center;
  padding-right: 15px;
  &.login {
    background: 20px;
    padding-right: 0;
  }
}
#account-links ul.dropdown li:last-child a {
  color: #b8b8b8;
  &:hover {
    color: #fff;
  }
}

h1.page-header {
  color: #a4a4a4;
  border-bottom: none;
  font-size: 24px;
  font-weight: 300;
  line-height: 65px;
}
.content-header {
  font-size: 24px;
  font-weight: 300;
  margin: 30px 0 0;
}
.page-user-security-tfa {
  .content-header {
    margin-bottom: 30px;
  }
}
.nav-tabs {
  border-bottom: none;
  margin-bottom: 0;
  padding-left: 3px;
  > li {
    padding-bottom: 0;
    margin-bottom: 0;
    &:focus {
      background: transparent;
    }
    > a {
      color: #373737;
      background: transparent;
      border-radius: 0;
      border-width: 0;
      line-height: 1em;
      display: inline-block;
      padding: 13px 15px;
      margin: 0;
      text-transform: uppercase;
      color: @gray-dark;
      font-size: 12px;
      &:focus {
        background: transparent;
      }
    }
    &:hover a {
      background: @brand-primary;
      margin: 0;
      color: #fff;
    }
  }
  > li.active {
    > a,
    > a:hover,
    > a:focus {
      background: @brand-primary;
      color: #fff;
    }
  }
}
.vertical-tabs .nav-tabs > li > a {
  text-transform: none;
  display: block;
}
.tabs-left, .tabs-right {
  > .tab-content,
  > .nav-tabs > li.selected,
  > .nav-tabs > li.selected > a {
    background: #fff;
  }
  > .nav-tabs > li {
    border-top: 1px solid #ddd;
    > a {
      margin-bottom: 0;
    }
  }
}
.header-wrapper {
  background: #fff;
  .account-picture {
    float: left;
    margin-right: 20px;
    padding-left: 16px;
    .user-picture,
    .user-picture a {
      width: 65px;
      display: inline-block;
    }
    img {
      border-radius: 65px;
      max-width: 100%;
      border: 1px solid @body-bg;
    }
  }
}
.header-container {
  padding-top: 20px;
}
body.admin-menu .navbar-fixed-top {
  top: 0;
}
body.navbar-is-fixed-top {
  padding-top: 0 !important;
}
html body.admin-menu {
  margin-top:0 !important;
  padding-top: 49px !important;
}
#block-universal-navbar-universal-navbar-account,
#block-universal-navbar-universal-navbar-menu {
  float: none;
}
#block-universal-navbar-universal-navbar-account {
  position: inherit;
}
.universal-navbar > li,
.item-list .universal-navbar > li {
  display: block;
}
.navbar-fixed-top,
.universal-navbar ul.dropdown,
.universal-navbar-account-links ul.dropdown {
  position: relative;
}
