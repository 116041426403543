// Bootstrap library.
@import 'variables.less';

// Base-theme overrides.
@import 'overrides.less';
@import 'forms.less';


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,700,600,300,800');

body {
  background-color: @body-bg;
  font-family: @font-family-sans-serif;
  &.not-logged-in.page-error-denied {
    background-color: #fff;
    .main-container {
      border-top: none;
    }
  }
  &.logged-in.page-error-denied {
    .region-content {
      padding-top: 50px;
    }
    .return {
      margin-top: 30px;
      text-align: left;
    }
  }
}
a,
a:hover {
  color: @brand-primary;
}


.maintenance-page .region-content {
  font-size: 16px;
  padding: 20px;
}

/* Override aui.css body style */
body.aui-page-hybrid {
  background-color: #ffffff;
}
/* Set a default height for the add-on content */
.in-bitbucket-context {
  .navbar-collapse.collapse,
  footer.footer,
  h1.page-header,
  .navbar-default .navbar-toggle {
    display: none !important;
  }
  &.page-checkout-complete {
    h1.page-header {
      display: block !important;
    }
  }
  #navbar .navbar-header {
    margin: 20px 0;
    float: none;
    text-align: center;
  }
  #navbar .logo {
    margin: 0 auto;
    display: inline-block;
    float: none !important;
  }
  .block-system {
    position: relative;
  }
  #content {
    height: 690px;
    padding: 25px;
  }
  .active-branches label {
    font-weight: 400;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .bitbucket-branch {
    background: #e8f7ff;
    padding: 0 12px;
    color: #000;
    font-weight: 500;
    font-size: 13px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin: 0 10px 10px 0;
    .ext-link {
      display: inline-block;
      float: right;
      height:20px;
      width: 20px;
      background: transparent url("@{image-path}/icon-external.png") no-repeat 0 0;
      margin: 5px 0 5px 10px;
      position: relative;
    }
  }
  .tooltip {
    overflow: visible;
    .tooltip-inner {
      min-width: 100%;
      border-radius: 0;
      white-space: pre-wrap;
      float: left;
      font-size: 11px;
    }
  }
  .sign-up-message {
    background: #fff;
    padding: 25px 50px;
    text-align: left;
  }
  .platformsh-logo {
    text-align: center;
    padding-bottom: 25px;
    img {
      width: 200px;
      max-width: 100%;
      height: auto;
    }
  }
  .platformsh-icon {
    float: left;
    margin: 0 10px 0 0;
    line-height: 25px;
  }
  .connection-status {
    line-height: 30px;
    font-size: 13px;
    span {
      background: #ededed;
      padding: 3px 8px;
      &.connected {
        background: #def4ff;
        font-weight: 500;
      }
    }
    .aui-button {
      background: #f2f2f2;
      margin-left: 10px;
      font-size: 13px;
      text-shadow: none;
      padding: 0 14px;
      line-height: 30px;
      height: 30px;
      border-color: transparent;
      &:hover {
        background: #ededed;
        border-color: transparent;
      }
    }
    .aui-button.aui-button-primary {
      background: #3572b0;
      border-color: transparent;
      &:hover {
        background: #2a67a5;
        border-color: transparent;
      }
    }
  }
  .status-wrapper {
    padding: 20px;
    border: 1px solid #e9e9e9;
    margin-bottom: 10px;
    position: relative;
    .connection-status,
    .connection-status .aui-button.aui-button-primary {
      margin-bottom: 0;
    }
    .aui-button-primary {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    p {
      display: inline-block;
      margin-right: 20px;
    }
    .platformsh-bitbucket-disconnect-link {
      a {
        font-size: 12px;
        line-height: 30px;
        color: #7d7d7d;
      }
    }
  }
  .bitbucket-settings-text {
    text-align: left;
    h2 {
      text-align: left;
      font-weight: 500;
      margin-bottom: 20px;
    }
    h3, h4 {
      margin-top: 30px;
    }
    p {
      margin-bottom: 15px;
    }
    blockquote {
      font-size: 14px;
      p:last-child {
        margin-bottom: 0;
      }
      &.note {
        background: #e0f5fd;
        border: none;
        color: #000;
        font-size: 14px;
        strong {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
      &.alert {
        background: #fcf8e3;
        border: none;
        color: #000;
        h3 {
          margin-top: 0;
        }
      }
    }
  }
  .sign-up-message {
    clear: right;
    text-align: left;
  }
  .connect-area {
    text-align: center;
    padding: 0 50px;
  }
  .platformsh-bitbucket-configure-link {
    position: absolute;
    bottom: 30px;
    text-align: center;
    width: 100%;
  }
  .connect-message {
    font-size: 20px;
    font-weight: 600;
    margin: 90px 0 60px;
  }
  .new-to {
    margin-top: 2em;
    font-size: 16px;
    font-weight: 600;
  }
  .description {
    color: #777878;
    margin-bottom: 40px;
  }
  ul.feature-list {
    li {
      background: transparent url("@{image-path}/caret-right.png") no-repeat 0 0;
      font-size: 14px;
      line-height: 1em;
      list-style: none;
      margin-bottom: 15px;
      padding: 0 0 0 30px;
    }
    li:first-child{
      background: transparent;
      margin-bottom: 20px;
      padding-left: 0;
      text-transform: uppercase;
    }
  }
  .platformsh-environments {
    width: 100%;
    td {
      max-width: 15em;
      overflow: hidden;
      padding: 0 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    tr {
      background-color: #ffffff;
      border: 0;
    }
  }
  .btn-default[disabled] {
    background-color: @brand-primary;
  }
  .platform-area {
    padding-top: 0;
    margin-bottom: 0;
  }
  #commerce-checkout-form-register .platform-area {
    padding-top: 0 !important;
  }
}
#platformsh-bitbucket-create-form,
#platformsh-bitbucket-configure-form,
#platformsh-bitbucket-login-form,
#platformsh-bitbucket-disconnect-form {
  background: #efefef;
  padding: 30px;
  .connect-area,
  .sign-up-message {
    min-height: 500px;
  }
}
#platformsh-bitbucket-create-form,
#platformsh-bitbucket-configure-form {
  .form-item-project,
  .form-actions,
  .warning {
    width: 260px;
    margin: 0 auto 20px;
    text-align: left;
  }
  .warning {
    font-weight: 600;
  }
  .form-item-project label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .form-actions {
    clear: none;
  }
  .or {
    padding-bottom: 20px;
    text-transform: uppercase;

  }
}
#platformsh-bitbucket-create-form {
  padding-bottom: 70px;
  .form-actions {
    text-align: center;
    margin-bottom: 0;
  }
}
#platformsh-bitbucket-disconnect-form .form-actions {
  padding-top: 30px;
}
/* Checkout pages in the bitbucket context */
.page-checkout.in-bitbucket-context {
  .page-wrapper {
    padding-top: 0 !important;
  }
}
/* Remove the default height for the repository info panel */
.in-bitbucket-context.is-panel {
  #content {
    height: auto;
    min-height: 40px;
    padding: 10px;
    text-align: left;
  }
}
@media (max-width: 960px) {
  .in-bitbucket-context .connect-area {
    padding: 0 30px;
  }
  .in-bitbucket-context .sign-up-message {
    padding: 25px 30px;
  }
}
@media (min-width: 992px) {
  .maintenance-page #logo-title,
  .maintenance-page #main{
    width: 970px;
  }
}
// Theme specific.
@import 'header.less';
@import 'content.less';
@import 'footer.less';

@import 'bootstrap-social';

// Repsonsive styles last.
@import 'responsive.less';

